import { DesktopWidths } from '~/constants/types/storyblok';

export default function useDesktopWidth(props: any) {
  const desktopWidth = (defaultWidth: DesktopWidths = 'full') => {
    const userSelected = props.blok?.visualAppearance?.desktopWidth || '';
    if (userSelected === 'recommended' || userSelected === '') {
      return `desktopMaxW-${defaultWidth}`;
    }
    return `desktopMaxW-${userSelected}`;
  };
  
  return {
    desktopWidth,
  };
}
